.project-details {
  .portfolio-details-slider {
    img {
      width: 100%;
    }
    .swiper-pagination {

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: rgba(#fff, .7);
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background-color: var(--color-primary);
      }
    }

  }

  .swiper-button-prev, .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  
  .swiper-button-prev:after, .swiper-button-next:after {
    color: rgba(#ffffff, .8);
    background-color: rgba(#000, .2);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .swiper-button-prev:hover, .swiper-button-next:hover {
    &:after {
      background-color: rgba(#000, .6);
    }
  }

  @media (max-width: 575px) {
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }

  .portfolio-info {

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 50px;
        height: 3px;
        background: var(--color-primary);
        left: 0;
        bottom: 0;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
      }

      strong {
        text-transform: uppercase;
        font-weight: 400;
        color: lighten(secondary, 20);
        font-size: 14px;
      }
    }

    .btn-visit {
      padding: 8px 40px;
      background: var(--color-primary);
      color: #fff;
      border-radius: 50px;
      transition: 0.3s;
      &:hover {
        background: lighten(primary, 5);
      }
    }
    
  }

  .portfolio-description {
    
    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--color-secondary);
    }

    p {
      padding: 0;
    }

    .testimonial-item {
      padding: 30px 30px 0 30px;
      position: relative;
      background:lighten(secondary, 62);
      height: 100%;
      margin-bottom: 50px;
  
      .testimonial-img {
        width: 90px;
        border-radius: 50px;
        border: 6px solid #fff;
        float: left;
        margin: 0 10px 0 0;
      }
  
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 15px 0 5px 0;
        padding-top: 20px;
      }
  
      h4 {
        font-size: 14px;
        color: #6c757d;
        margin:0;
      }
  
      .quote-icon-left, .quote-icon-right {
        color: lighten(primary, 20);
        font-size: 26px;
        line-height: 0;
      }
  
      .quote-icon-left {
        display: inline-block;
        left: -5px;
        position: relative;
      }
  
      .quote-icon-right {
        display: inline-block;
        right: -5px;
        position: relative;
        top:10px;
        transform: scale(-1, -1);
      }
  
      p {
        font-style: italic;
        margin: 0 0 15px 0 0 0;
        padding: 0;
      }
  
    }
  }
}

.copyright{
  padding: 20px 40px;
  border-top: 2px solid rgba(0,0,0, 0.1);
  background: rgba(228,222,222,);
  text-align: center;
}

.copyright p:nth-child(1){
  color: #333;
}

.copyright a {
  color: #acac15;
  text-decoration: none;
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: Georgia, sans-serif;
}
 header {
  font-family: Georgia, sans-serif;
}





